// Library Imports
import React, { useState, useEffect, useCallback } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { URL } from '../../../variables';
import { connect } from 'react-redux';


const INITIAL_FORM_DATA = {
  tema: "",
  disciplina: "",
  tipoQuestao: "",
  nivelEscolaridade: "",
  nivelDificuldade: "",
  quantidadeAlternativas: "",
};

const TIPOS_QUESTAO = ["Questão objetiva", "Questão dissertativa"];
const NIVEIS_ESCOLARIDADE = [
  "Ensino Fundamental",
  "Ensino Médio",
  "Ensino Superior",
];
const NIVEIS_DIFICULDADE = ["Fácil", "Médio", "Difícil"];

const ContextForm = ({ onSubmit, token }) => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [outraDisciplina, setOutraDisciplina] = useState("");
  const [showOutraDisciplina, setShowOutraDisciplina] = useState(false);
  const [disciplinas, setDisciplinas] = useState([]);

  const fetchDisciplinas = async () => {
    try {
      const response = await fetch(`${URL}api/faculdade/get_categorias`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`, 
        },
      });
  
      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      if (data && Array.isArray(data.categorias)) {
        const nomesCategorias = data.categorias.map(categoria => categoria.name);
        setDisciplinas(nomesCategorias);
      } else {
        console.error("A resposta não contém categorias ou não é um array:", data);
      }
    } catch (error) {
      console.error("Erro ao buscar disciplinas:", error);
    }
  };
  
  useEffect(() => {
    fetchDisciplinas();
  }, []);
  

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (name === "disciplina") {
        const isOutra = value === "Outra";
        setShowOutraDisciplina(isOutra);
        setFormData((prev) => ({
          ...prev,
          [name]: isOutra ? outraDisciplina : value,
        }));
        if (!isOutra) {
          setOutraDisciplina("");
        }
      } else {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    },
    [outraDisciplina]
  );

  const handleOutraDisciplinaChange = useCallback((e) => {
    const { value } = e.target;
    setOutraDisciplina(value);
    setFormData((prev) => ({ ...prev, disciplina: value }));
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(formData);
      setFormData(INITIAL_FORM_DATA);
      setOutraDisciplina("");
      setShowOutraDisciplina(false);
      localStorage.removeItem("currentQuestionId");
    },
    [formData, onSubmit]
  );

  const renderFormGroup = (
    label,
    name,
    options,
    placeholder,
    required = true
  ) => (
    <Form.Group className="mb-3">
      <Form.Label>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </Form.Label>
      {options ? (
        <Form.Select
          name={name}
          value={formData[name]}
          onChange={handleChange}
          required={required}
        >
          <option value="">{`Selecione ${label.toLowerCase()}`}</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Form.Select>
      ) : (
        <Form.Control
          type="text"
          name={name}
          value={formData[name]}
          onChange={handleChange}
          placeholder={placeholder}
          required={required}
        />
      )}
    </Form.Group>
  );

  return (
    <Card className="context-form">
      <Card.Body>
        <Card.Title>Defina o Contexto da Questão</Card.Title>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              Disciplina <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Select
              name="disciplina"
              value={showOutraDisciplina ? "Outra" : formData.disciplina}
              onChange={handleChange}
              required
            >
              <option value="">Selecione a disciplina</option>
              {disciplinas.map((disciplina, index) => (
                <option key={index} value={disciplina}>
                  {disciplina}
                </option>
              ))}
              <option value="Outra">Outra</option>
            </Form.Select>
            {showOutraDisciplina && (
              <Form.Control
                type="text"
                placeholder="Digite a disciplina"
                value={outraDisciplina}
                onChange={handleOutraDisciplinaChange}
                className="mt-2"
                required
              />
            )}
          </Form.Group>

          {renderFormGroup("Tipo de questão", "tipoQuestao", TIPOS_QUESTAO)}

          {formData.tipoQuestao === "Questão objetiva" && (
            <Form.Group className="mb-3">
              <Form.Label>
                Quantidade de Alternativas{" "}
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                name="quantidadeAlternativas"
                value={formData.quantidadeAlternativas}
                onChange={handleChange}
                required
              >
                <option value="">Selecione a quantidade</option>
                {[2, 3, 4, 5].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}

          {renderFormGroup(
            "Nível de Escolaridade",
            "nivelEscolaridade",
            NIVEIS_ESCOLARIDADE
          )}

          {renderFormGroup(
            "Nível de Dificuldade",
            "nivelDificuldade",
            NIVEIS_DIFICULDADE
          )}

          {renderFormGroup("Tema principal", "tema", null, "Ex: Revolução Francesa")}

          <Form.Group className="mb-3">
            <Form.Label>Contexto</Form.Label>
            <Form.Control
              type="text"
              name="contexto"
              value={formData.contexto || ""}
              onChange={handleChange}
              placeholder="Descreva o assunto específico da questão"
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Gerar Questão
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapsStateToProps = (state) => (
  {
      token: state.AppReducer.token,
  }
);

export default connect(mapsStateToProps)(ContextForm);
