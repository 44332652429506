import axios from "axios";
import { API_URL } from "../variables";

export const fetchChatBotResponse = async (temaRedacao, redacaoTexto) => {
  try {
    const response = await axios.post(
      API_URL+"avaliar-redacao",
      {
        temaRedacao,
        redacaoTexto
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Erro na resposta da API");
    }
console.log("o return foi: ", response)
    return response.data.answer;
  } catch (error) {
    console.error("Erro ao chamar a API:", error);
    throw new Error(
      "Ocorreu um erro ao obter a resposta. Tente novamente mais tarde."
    );
  }
};
