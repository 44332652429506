import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaDownload, FaListAlt, FaPlus, FaVideo } from "react-icons/fa";
import {
  MdDelete,
  MdModeEdit,
  MdPlusOne,
  MdVideoLibrary,
  MdVisibility,
} from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from "@material-ui/lab/Pagination";
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from "file-saver";
import ModalAddZip from "./ModalAddZip";
import axios from "axios";
import { API_URL, URL } from "../../variables";
import { connect } from "react-redux";

const ResultadosRedacaoIA = (props) => {
  const {
    redacao_id,
    redacao_name,
    processo_id,
    processo_name,
    token, // Add this
  } = props;

  const [lesson_cards, SetLessonCards] = useState(null);
  const [loading_screen, setLoadingScreen] = useState(true);
  const [showModalRedacao, setShowModalRedacao] = useState(false);

  const [loading_redacoes, setLoadingredacoes] = useState(false);
  const [search_oficial, setSearchOficial] = useState("");

  const [redacoes, setredacoes] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
  });

  const [search, setSearch] = useState("");
  const [show_warning, setShowWarning] = useState(false);
  const [redacoes_id, setRedacaoId] = useState(null);

  const [questaoSelect, setQuestaoSelect] = useState({});
  const [selectedRedacoes, setSelectedRedacoes] = useState([]);
  const [selectedRedacao, setSelectedRedacao] = useState(null);

  const [showModal, setShowModal] = useState(false);
  let refs = [];
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progresso, setProgresso] = useState(0);
  const [total, setTotal] = useState(0);

  // Add new state for separate loading indicators
  const [isLoadingSelected, setIsLoadingSelected] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isLoadingBaixar, setIsLoadingBaixar] = useState(false);
  const [progressoBaixar, setProgressoBaixar] = useState(0);
  const [totalBaixar, setTotalBaixar] = useState(0);

  const Getredacoes = async (page, search) => {
    let response = await Request.Get(
      `faculdade/get_resultados_redacao/${redacao_id}?page=${page}&search=${search}`
    );
    console.log("redacoes: ", response);
    if (response === undefined) return;
    if (response["message"] == "Unauthenticated.") {
      dispatch(logout());
      return;
    }
    if (response.pagination.current_page == page) {
      setredacoes(response.redacoes);

      setPagination(response.pagination);
      setLoadingScreen(false);
      setLoadingredacoes(false);
    }
  };

  const DeleteRedacao = async (redacoes_id) => {
    let response = await Request.Delete(
      `faculdade/desvincular_redacao/${redacoes_id}`
    );
    console.log(response);
    if (response === undefined) return;

    if (response["message"] == "Unauthenticated.") {
      dispatch(logout());
      return;
    }
    Getredacoes(pagination.current_page, search_oficial);
  };

  useEffect(() => {
    Getredacoes(1, search_oficial);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const handleSelectRedacao = (redacao) => {
    setSelectedRedacoes((prev) => {
      const isSelected = prev.includes(redacao.id);
      if (isSelected) {
        return prev.filter((id) => id !== redacao.id);
      } else {
        console.log("Selected essay text:", redacao.texto);
        return [...prev, redacao.id];
      }
    });
  };

  const handleSelectAllRedacoes = () => {
    if (selectedRedacoes.length === redacoes.length) {
      setSelectedRedacoes([]);
    } else {
      setSelectedRedacoes(redacoes.map((redacao) => redacao.id));
    }
  };

  // Add separate progress checking function
  const checkRedacoesProgress = async () => {
    const checkProgress = setInterval(async () => {
      try {
        const progressoResponse = await axios.get(
          `${API_URL}faculdade/progresso-redacoes/${processo_id}`,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            },
          }
        );

        setProgresso(progressoResponse.data.progresso);
        setTotal(progressoResponse.data.total);

        if (progressoResponse.data.progresso === progressoResponse.data.total) {
          clearInterval(checkProgress);
          setIsLoadingSelected(false);
          setIsLoading(false);

          // Download CSV after completion
          window.location.href = URL+ `respostas_redacoes_${processo_id}.csv`;

          // Refresh table data
          await Getredacoes(pagination.current_page, search_oficial);
        }
      } catch (error) {
        console.error("Erro ao verificar o progresso:", error);
        clearInterval(checkProgress);
        setIsLoadingSelected(false);
        setIsLoading(false);
      }
    }, 5000);
  };

  const checkRedacoesSalvasProgress = async () => {
    const checkProgress = setInterval(async () => {
      try {
        const progressoResponse = await axios.get(
          `${API_URL}correcao-redacao-ia/progresso/${processo_id}`,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            },
          }
        );

        setProgresso(progressoResponse.data.progresso);
        setTotal(progressoResponse.data.total);

        if (progressoResponse.data.progresso === progressoResponse.data.total) {
          clearInterval(checkProgress);
          setIsLoadingSelected(false);
          setIsLoading(false);
          setIsLoadingBaixar(false); // Add this line to end the loading state

          // Download CSV after completion
          window.location.href = `${URL}respostas_salvas_redacoes_${processo_id}.csv`;

          // Refresh table data
          await Getredacoes(pagination.current_page, search_oficial);
        }
      } catch (error) {
        console.error("Erro ao verificar o progresso:", error);
        clearInterval(checkProgress);
        setIsLoadingSelected(false);
        setIsLoading(false);
        setIsLoadingBaixar(false); // Add this line to end loading state on error
      }
    }, 5000);
  };

  // Update handleCorrigirSelecionadas
  const handleCorrigirSelecionadas = async () => {
    setProgresso(0);
    setTotal(0);
    setIsLoadingSelected(true);

    if (selectedRedacoes.length === 0) {
      alert("Por favor, selecione pelo menos uma redação");
      setIsLoadingSelected(false);
      return;
    }

    // Get only selected redações
    const selectedRedacoesData = redacoes.filter((redacao) =>
      selectedRedacoes.includes(redacao.id)
    );

    const data = {
      processo_id: props.processo_id,
      redacoes: selectedRedacoesData.map((redacao) => ({
        id: redacao.id,
        texto: redacao.texto,
      })),
    };

    try {
      setIsLoading(true);
      await axios.post(`${API_URL}faculdade/corrigir-todas-redacoes`, data, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      // Start progress checking
      await checkRedacoesProgress();
    } catch (error) {
      console.error("Erro ao corrigir a redação:", error);
      setIsLoadingSelected(false);
      setIsLoading(false);
    }
  };

  // Add new function to fetch all redações
  const getAllRedacoes = async () => {
    try {
      // Get first page to get total pages
      const firstPage = await Request.Get(
        `faculdade/get_resultados_redacao/${redacao_id}?page=1&search=${search_oficial}`
      );

      const totalPages = firstPage.pagination.last_page;
      let allRedacoes = [...firstPage.redacoes];

      // Fetch remaining pages
      for (let page = 2; page <= totalPages; page++) {
        const response = await Request.Get(
          `faculdade/get_resultados_redacao/${redacao_id}?page=${page}&search=${search_oficial}`
        );
        allRedacoes = [...allRedacoes, ...response.redacoes];
      }

      return allRedacoes;
    } catch (error) {
      console.error("Erro ao buscar todas as reda��ões:", error);
      return [];
    }
  };

  // Add new function to fetch all redações without pagination
  const getAllRedacoesWithoutPaginate = async () => {
    try {
      const response = await Request.Get(
        `faculdade/get_all_resultados_redacao/${redacao_id}`
      );
      return response.redacoes || [];
    } catch (error) {
      console.error("Erro ao buscar todas as redações:", error);
      return [];
    }
  };

  // Update handleCorrigirTodas
  const handleCorrigirTodas = async () => {
    setProgresso(0);
    setTotal(0);
    setIsLoadingAll(true);

    try {
      // Get all redações without pagination
      const allRedacoes = await getAllRedacoesWithoutPaginate();

      // Select all redações
      setSelectedRedacoes(allRedacoes.map((redacao) => redacao.id));

      const data = {
        processo_id: props.processo_id,
        redacoes: allRedacoes.map((redacao) => ({
          id: redacao.id,
          texto: redacao.texto,
        })),
      };

      await axios.post(`${API_URL}faculdade/corrigir-todas-redacoes`, data, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      // Start progress checking
      await checkRedacoesProgress();
    } catch (error) {
      console.error("Erro ao corrigir todas as redações:", error);
      setIsLoadingAll(false);
    }
  };

  const handleBaixarRedacoesSalvas = async () => {
    setProgresso(0);
    setTotal(0);
    setIsLoadingBaixar(true);

    try {
      await axios.post(API_URL+'salvar-respostas-ia', {
        processo_id: props.processo_id,
      }, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      // Start checking progress
      await checkRedacoesSalvasProgress();
    } catch (error) {
      console.error('Erro ao baixar redações salvas:', error);
      setIsLoadingBaixar(false);
    }
  };

  let pesquisarTimeout = null;
  const history = useHistory();
  return (
    <div className="row">
      <div className="col-12">
        {/* <hr /> */}
        {loading_screen == false && (
          <div>
            <p className="text-muted">
              Aqui ficam as respostas da redação "{redacao_name}"
            </p>

            {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
            {/* <br /> */}
            <a id="download_link" target="_blank"></a>
            <div className="flex jcsb flex-wrap mb-4">
              <Search
                placeholder="Pesquisar pelo nome do candidato"
                ValueChange={(value) => {
                  clearTimeout(pesquisarTimeout);
                  setSearchOficial(value);

                  pesquisarTimeout = setTimeout(() => {
                    let page = { ...pagination };
                    page.current_page = 1;
                    setPagination(pagination);
                    setLoadingredacoes(true);

                    Getredacoes(1, value);
                  }, 500);
                }}
                />
                <div style={{ display: "flex", gap: "15px" }}>
                <Button
                  variant="success"
                  className="flex align-center jcc"
                  onClick={handleCorrigirSelecionadas}
                  disabled={isLoadingSelected || isLoadingAll}
                >
                  <FaPlus style={{ marginRight: 10 }} />
                  {isLoadingSelected ? (
                  <>
                    {total === 0
                    ? "Iniciando correção..."
                    : `Corrigindo... (${progresso}/${total})`}
                    <CircularProgress size={20} style={{ marginLeft: 10 }} />
                  </>
                  ) : (
                  "Corrigir Selecionadas"
                  )}
                </Button>

                <Button
                  variant="success"
                  className="flex align-center jcc"
                  onClick={handleCorrigirTodas}
                  disabled={isLoadingSelected || isLoadingAll}
                >
                  <FaPlus style={{ marginRight: 10 }} />
                  {isLoadingAll ? (
                  <>
                    {total === 0
                    ? "Iniciando correção..."
                    : `Corrigindo... (${progresso}/${total})`}
                    <CircularProgress size={20} style={{ marginLeft: 10 }} />
                  </>
                  ) : (
                  "Corrigir todas as Redações"
                  )}
                </Button>
                <Button
                  variant="success"
                  className="flex align-center jcc"
                  onClick={handleBaixarRedacoesSalvas}
                  disabled={isLoadingSelected || isLoadingAll || isLoadingBaixar}
                >
                  <FaDownload style={{ marginRight: 10 }} />
                  {isLoadingBaixar ? (
                  <>
                    {progresso === 0
                    ? "Iniciando download..."
                    : `Baixando... (${progresso}/${total})`}
                    <CircularProgress size={20} style={{ marginLeft: 10 }} />
                  </>
                  ) : (
                  "Baixar Redações Salvas"
                  )}
                </Button>
                </div>
              </div>
              {showModalRedacao == true && (
                <ModalAddZip
                redacao_id={redacao_id}
                showModal={showModalRedacao}
                setShowModal={(e) => {
                  setShowModalRedacao(e);

                  setLoadingScreen(true);
                  Getredacoes(1, search_oficial);
                }}
              />
            )}
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <table id="tech-companies-1" className="table table-striped">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={handleSelectAllRedacoes}
                          checked={selectedRedacoes.length === redacoes.length}
                        />
                      </th>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>CPF</th>
                      <th>Status</th>
                      <th data-priority="3">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading_redacoes == false &&
                      redacoes.map((item, id) => (
                        <tr key={id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRedacoes.includes(item.id)}
                              onChange={() => handleSelectRedacao(item)}
                            />
                          </td>
                          <td>{item.user.name}</td>
                          <td>{item.user.email}</td>
                          <td>{item.user.cpf}</td>
                          {item.statusCorrecao == "corrigida" && (
                            <td style={{ color: "green" }}>{"Corrigida"}</td>
                          )}
                          {item.statusCorrecao == "nao-corrigida" && (
                            <td style={{ color: "darkgoldenrod" }}>
                              {"Não Corrigida"}
                            </td>
                          )}

                          {item.processo_resultado.status == "Ausente" && (
                            <td style={{ color: "ref" }}>{"Ausente"}</td>
                          )}
                          {props.renderAcoes ? (
                            props.renderAcoes(item)
                          ) : (
                            <td>
                              {(item.processo_resultado.status == "F" ||
                                item.processo_resultado.status == "I") && (
                                <>
                                  <button
                                    onClick={() => {
                                      console.log(
                                        "Redação selecionada:",
                                        item.id
                                      ); // Adiciona o console.log aqui
                                      props.onVisualizarCorrecao(item.id); // Passa o redacaoId aqui
                                    }}
                                    className="btn btn-primary"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <MdVisibility />
                                    {""}
                                    {/* Ícone de visualização */}
                                  </button>
                                </>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
                {loading_redacoes == true && (
                  <div className="row" style={{ margin: 0 }}>
                    {" "}
                    <div className="col-12 d-flex justify-content-center align-items-center">
                      {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                      <CircularProgress />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {loading_redacoes == false && redacoes.length == 0 && (
              <div className="row w-100">
                <div className="col-12">
                  <p style={{ textAlign: "center" }}>
                    Nenhuma redação encontrada
                  </p>
                </div>
              </div>
            )}

            <Pagination
              className={pagination.last_page == 1 ? "hide" : ""}
              style={{ display: "flex", justifyContent: "flex-end" }}
              count={pagination.last_page}
              value={pagination.current_page}
              onChange={(_, value) => {
                setLoadingredacoes(true);
                console.log(_, value);
                let page = { ...pagination };
                page.current_page = value;
                setPagination(pagination);
                Getredacoes(value, search_oficial);
              }}
            />

            {/* {this.state.andamento == true && <AvaliacaoAndamento get_redacoes={this.get_redacoes.bind(this)} {...this.props} />} */}
          </div>
        )}

        {loading_screen == true && (
          <div className="row">
            {" "}
            <div
              className="col-12 d-flex justify-content-center align-items-center"
              style={{ marginTop: "2rem" }}
            >
              {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
              <CircularProgress />
            </div>
          </div>
        )}

        <SweetAlert
          warning
          title={"Atenção"}
          onConfirm={() => {
            setShowWarning(false);
            setLoadingredacoes(true);
            DeleteRedacao(redacoes_id);
            // this.delete_banner(this.state.banner_to_delete);
          }}
          onCancel={() => {
            setShowWarning(false);
          }}
          show={show_warning}
          confirmBtnText="Sim, desejo desvincular"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          showCancel={true}
        >
          Ao desvincular este redacao, ele não terá mais acesso a este processo
        </SweetAlert>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapStateToProps)(ResultadosRedacaoIA); // Make sure connect is used
